import { getStorage, setStorage, removeStorage } from "@/common/utils/Storage.js"

const state = {
    menuShow: false,
    lang: getStorage('lang'),
}

const mutations = {
    //菜单
    SET_MENU: (state, data) => {
        state.menuShow = data
    },
    SET_LANG: (state, data) => {
        state.lang = data
        setStorage('lang', data)
    },
    REMOVE_LANG: (state, data) => {
        state.lang = data
        removeStorage('lang')
    },
}

const actions = {
    //菜单
    set_menu({
        commit
    }, data) {
        commit('SET_MENU', data)
    },
    set_lang({
        commit
    }, data) {
        commit('SET_LANG', data)
    },
    remove_lang({
        commit
    }) {
        commit('REMOVE_LANG', "")
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}