<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    safe-area-inset-bottom
    :get-container="getContainer"
  >
    <div class="popup-content">
      <div class="popup-view">
        <div class="popup-head">{{ $t("lang.languageSwitching") }}</div>
        <div class="popup-box">
          <van-radio-group v-model="value">
            <van-cell-group>
              <van-cell
                v-for="(item, index) in columns"
                :key="index"
                :title="$t(item.text)"
                clickable
                @click="value = item.value"
              >
                <template #right-icon>
                  <van-radio :name="item.value" checked-color="#b48418" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
      </div>
      <div class="popup-button">
        <div class="popup-cancel" @click="cancel">
          {{ $t("common.cancel") }}
        </div>
        <div class="popup-confirm" @click="confirm">
          {{ $t("common.confirm") }}
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      lang: (state) => state.system.lang,
    }),
    columns() {
      return [
        { text: "lang.zh", value: "zh" },
        { text: "lang.en", value: "en" },
      ];
    },
  },
  data() {
    return {
      visible: false,
      value: this.lang,
    };
  },
  mounted() {
    this.value = this.lang;
  },
  methods: {
    getContainer() {
      return document.querySelector("#app");
    },
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    /**
     * 取消
     */
    cancel() {
      this.close();
      this.value = this.lang;
    },
    /**
     * 确认
     */
    confirm() {
      this.close();
      this.$i18n.locale = this.value;
      this.$store.dispatch("system/set_lang", this.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.van-popup {
  background: none;
}
.popup-content {
  width: calc(100vw - 40px);
  overflow: hidden;
  border-radius: 20px;
  .popup-view {
    background-color: #fff;
    padding: 0 33px;
    .popup-head {
      height: 107px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 39px;
      font-weight: 500;
      color: #b48418;
      border-bottom: 1px solid #b7b7b7;
    }
    .popup-box {
      padding: 45px 0;
      .input {
        background-color: #eee;
        border-radius: 8px;
        text-align: center;
      }
    }
  }
  .popup-button {
    height: 104px;
    display: flex;
    > * {
      width: 50%;
      height: 100%;
      font-size: 34px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .popup-cancel {
      background-color: #daab42;
    }
    .popup-confirm {
      background-color: #b48418;
    }
  }
}

.popup-content-draw {
  padding: 0 30px;
}
</style>
