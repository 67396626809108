export function getWalletAddress() {
    return sessionStorage.getItem('walletAddress')
}
export function setWalletAddress(data) {
    sessionStorage.setItem('walletAddress', data)
}
export function removeWalletAddress() {
    sessionStorage.removeItem('walletAddress')
}
export function getWalletAddressTronLink() {
    return sessionStorage.getItem('walletAddressTronLink')
}
export function setWalletAddressTronLink(data) {
    sessionStorage.setItem('walletAddressTronLink', data)
}
export function removeWalletAddressTronLink() {
    sessionStorage.removeItem('walletAddressTronLink')
}

export function getToken() {
    return sessionStorage.getItem('Token')
}
export function setToken(data) {
    sessionStorage.setItem('Token', data)
}
export function removeToken() {
    sessionStorage.removeItem('Token')
}

export function getCode() {
    return sessionStorage.getItem('code')
}
export function setCode(data) {
    sessionStorage.setItem('code', data)
}
export function removeCode() {
    sessionStorage.removeItem('code')
}

export function getCoolingTime() {
    return sessionStorage.getItem('coolingTime')
}
export function setCoolingTime(data) {
    sessionStorage.setItem('coolingTime', data)
}
export function removeCoolingTime() {
    sessionStorage.removeItem('coolingTime')
}


const state = {
    walletAddress: getWalletAddress(),
    walletAddressTronLink: getWalletAddressTronLink(),
    token: getToken(),
    code: getCode(),
    coolingTime: getCoolingTime(),
}

const mutations = {
    //钱包地址设置
    SET_WALLET_ADDRESS: (state, data) => {
        state.walletAddress = data
        setWalletAddress(data)
    },
    //钱包地址删除
    REMOVE_WALLET_ADDRESS: (state, data) => {
        state.walletAddress = data
        removeWalletAddress(data)
    },
    //钱包地址设置
    SET_WALLET_ADDRESS_TRON_LINK: (state, data) => {
        state.walletAddressTronLink = data
        setWalletAddressTronLink(data)
    },
    //钱包地址删除
    REMOVE_WALLET_ADDRESS_TRON_LINK: (state, data) => {
        state.walletAddressTronLink = data
        removeWalletAddressTronLink(data)
    },

    //设置token
    SET_TOKEN: (state, data) => {
        state.token = data
        setToken(data)
    },
    //删除token
    REMOVE_TOKEN: (state, data) => {
        state.token = data
        removeToken(data)
    },

    //设置code
    SET_CODE: (state, data) => {
        state.code = data
        setCode(data)
    },
    //删除code
    REMOVE_CODE: (state, data) => {
        state.code = data
        removeCode()
    },

    //设置 冷却时间
    SET_COOLING_TIME: (state, data) => {
        state.coolingTime = data
        setCoolingTime(data)
    },
    //删除 冷却时间
    REMOVE_COOLING_TIME: (state, data) => {
        state.coolingTime = data
        removeCoolingTime()
    },
}

const actions = {
    //钱包地址设置
    set_wallet_address({
        commit
    }, data) {
        commit('SET_WALLET_ADDRESS', data)
    },
    //钱包地址删除
    remove_wallet_address({
        commit
    }) {
        commit('REMOVE_WALLET_ADDRESS', "")
    },
    //钱包地址设置
    set_wallet_address_tron_link({
        commit
    }, data) {
        commit('SET_WALLET_ADDRESS_TRON_LINK', data)
    },
    //钱包地址删除
    remove_wallet_address_tron_link({
        commit
    }) {
        commit('REMOVE_WALLET_ADDRESS_TRON_LINK', "")
    },

    //token设置
    set_token({
        commit
    }, data) {
        commit('SET_TOKEN', data)
    },
    //token删除
    remove_token({
        commit
    }) {
        commit('REMOVE_TOKEN', "")
    },


    //code设置
    set_code({
        commit
    }, data) {
        commit('SET_CODE', data)
    },
    //code删除
    remove_code({
        commit
    }) {
        commit('REMOVE_CODE', "")
    },


    //冷却时间 设置
    set_cooling_time({
        commit
    }, data) {
        commit('SET_COOLING_TIME', data)
    },
    //冷却时间 删除
    remove_cooling_time({
        commit
    }) {
        commit('REMOVE_COOLING_TIME', "")
    },

    //删除所有缓存
    remove_data({
        commit
    }) {
        commit('REMOVE_TOKEN', "")
        commit('SET_CODE', "")
        commit('REMOVE_CODE', "")
        commit('REMOVE_COOLING_TIME', "")
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}