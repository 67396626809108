import axios from 'axios';
import {
    is_mock
} from '@/common/config';
import store from '@/store'
import router from '@/router'

const $http = axios.create({
    baseURL: is_mock ? '' : process.env.VUE_APP_URL,
    timeout: 1000 * 12
});
// 设置post请求头
// $http.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// $http.defaults.headers.post['Content-Type'] = 'multipart/form-data';

// 请求前拦截器
$http.interceptors.request.use(
    config => {
        config.headers = {
            'token': store.state.user.token || "",
            'Content-Type': 'application/json'
        };
        return config;
    },
    error => {
        //请求错误时做些事
        return Promise.reject(error);
    }
);

// 请求后拦截
$http.interceptors.response.use(
    response => {
        if (response.status != 200) {
            console.log("啊偶！断网了...")
        }
        if (response.data.code == 401) {
            store.dispatch("user/remove_wallet_address");
            store.dispatch("user/remove_token");
            store.dispatch("user/remove_code");
            window.location.reload()
        }
        return response.data;
    },
    error => {
        // console.log("服务出现错误，请稍后再试!")
    }
);

export default $http;