//中文-繁体
module.exports = {
    lang: {
        languageSwitching: "语言切换",
        zh: "中文",
        en: "英文",
    },
    common: {
        // 通用
        ok: "确定",
        cancel: "取消",
        delete: "删除",
        edit: "编辑",
        add: "添加",
        save: "保存",
        reset: "重置",
        search: "搜索",
        confirm: "确认",
        tips: "提示",
        error: "错误",
        warning: "警告",
        info: "信息",
        success: "成功",
        submit: "提交",
        month: "月",
        day: "天",
        hour: "时",
        minute: "分",
        second: "秒",
        // 系统
        walletAddress: "钱包地址",
        buy: "购买",
        number: "数量",
        address: "地址",
        exchange: "兑换",
        balance: "余额",
        maximum: "最大"
    },
    warning: {
        min: "最低数量",
        max: "最大数量",
        chainError: "链错误",
        balance: "余额不足",
        paymentSuccessful: "支付成功",
        paymentFailed: "支付失败",
        copySuccess: "复制成功",
        copyFailed: "复制失败",
        withdrawalQuantity: "请输入复投数量",
        withdrawalQuantityBalance: "复投余额不足",
        withdrawalSuccessful: "复投成功",
        withdrawalFailed: "复投失败",
        searchAddress: "请输入地址进行搜索"
    },
    mescroll: {
        textInOffset: "下拉刷新",
        textOutOffset: "释放更新",
        textLoading: "加载中...",
        loading: "加载中...",
        empty: "暂无数据",
        end: "没有更多了"
    },
    menu: {
        notOpen: "未开放",
        home: "首页",
        pledge: "质押",
        center: "中心",
        ai: "AI智能",
        chainGame: "链游",
        manageMoney: "理财",
        metaverse: "元宇宙",
        transaction: "交易",
        market: "RWA赛道",
        vote: "投票",
        contactUs: "联系我们",
        languageSwitching: "语言切换",
    },
    router: {
        home: "首页",
        privateEquityList: "私募列表",
        share: "分享",
        center: "中心",
        invitationList: "邀请列表",
        pledge: "质押",
        pledgeList: "质押列表",
        withdrawal: "复投",
        recommendedIncomeList: "推荐收益列表",
        totalReleaseFXSList: "总释放FXS列表",
        totalRevenueFXSList: "总收益FXS列表",
        listOfTotalPledges: "质押总量列表",
        manage: "管理",
        kline: "交易"
    },
    home: {
        introduction: "简介",
        introductionText: `Frax Share代币（FXS）是生态中唯一流通的商业价值传输媒介。应用于RWA赛道，挖矿，AI人工智能，元宇宙，swap，Web3.0，Domino公链等场景，它还作为生态治理代币存在持有者可以行使参与投票决策的权利，从而管理整个生态。以数据传输为主体，以其他应用场景为起点，RWA赛道同时也是世界Web3.0的未来趋势。`,
        currentPrice: "当前价格"
    },
    pledge: {
        pledgeQuantity: "质押数量",
        placeholder: "请输入质押数量"
    },
    center: {
        extractable: "可提取",
        recommendedIncome: "推荐收益",
        totalRevenue: "总收益",
        totalRelease: "总释放",
        totalPledge: "质押总量",
        myMarketPledgeQuantity: "我的市场质押量",
        invitationLink: "邀请链接",
        userStakingAmount: "用户质押量"
    },
    withdrawal: {
        withdrawalQuantity: "复投数量",
    },
    manage: {
        privatePlacementQuantity: "私募数量",
        pledgeQuantity: "质押数量"
    },
    klinechart: {
        labels: ["时间", "开", "收", "高", "低", "成交量"]
    }
}