import request from '@/request';

/**
 * 通过地址获取token
 */
export function login(data) {
    return request({
        url: `/api/login`,
        method: 'post',
        data
    })
}

/**
 * 私募/质押 列表
 * @param {*} data 
 * type 1=私募列表 2=质押列表
 * @returns 
 */
export function getFinanceList(data) {
    return request({
        url: `/api/getFinanceList`,
        method: 'post',
        data
    })
}

/**
 * 用户信息
 * @param {*} data 
 * @returns 
 */
export function getUserInfo(data) {
    return request({
        url: `/api/getUserInfo`,
        method: 'post',
        data
    })
}

/**
 * 邀请列表
 * @param {*} data 
 * @returns 
 */
export function getInvitationList(data) {
    return request({
        url: `/api/getInvitationList`,
        method: 'post',
        data
    })
}


/**
 * 首页
 * @param {*} data 
 * @returns 
 */
export function getHomeData(data) {
    return request({
        url: `/api/getHomeData`,
        method: 'post',
        data
    })
}
/**
 * 总收益FXS 列表
 * @param {*} data 
 * @returns 
 */
export function getOrdersList(data) {
    return request({
        url: `/api/getOrdersList`,
        method: 'post',
        data
    })
}


/**
 * 提币
 * @param {*} data 
 * @returns 
 */
export function withdrawal(data) {
    return request({
        url: `/api/withdrawal`,
        method: 'post',
        data
    })
}


/**
 * 管理列表
 * @param {*} data 
 * @returns 
 */
export function getUSerPladgeList(data) {
    return request({
        url: `/api/getUSerPladgeList`,
        method: 'post',
        data
    })
}


/**
 * 兑换
 * @param {*} data 
 * @returns 
 */
export function setExchange(data) {
    return request({
        url: `/api/test`,
        method: 'post',
        data
    })
}

/**
 * 获取所有 k线
 * @param {*} data 
 * @returns 
 */
export function getKlinelist(data) {
    return request({
        url: `/api/getKlinelist`,
        method: 'post',
        data
    })
}

/**
 * 获取轮询 k线
 * @param {*} data 
 * @returns 
 */
export function getnewKline(data) {
    return request({
        url: `/api/getnewKline`,
        method: 'post',
        data
    })
}

/**
 * 获取所有列表数据
 * @param {*} data 
 * @returns 
 */
export function gettradelist(data) {
    return request({
        url: `/api/gettradelist`,
        method: 'post',
        data
    })
}

/**
 * 获取轮询列表数据
 * @param {*} data 
 * @returns 
 */
export function getnewtrade(data) {
    return request({
        url: `/api/getnewtrade`,
        method: 'post',
        data
    })
}
