/**
 * 测试demo
 * @param {*} data  数据
 */
export const test = (data) => {
  return data;
};

/**
 * 处理 null 数据处理
 * @param {*} data  原始数据值
 * @param {*} callback_data 返回数据类型
 */
export const handle_null = (data, callback_data) => {
  if (!data && typeof data != "undefined" && data != 0) {
    return callback_data;
  } else {
    return data;
  }
};

/**
 * 钱包地址 安全隐藏
 * @param {*} str
 * @returns
 */
export const walletAddressHide = (str) => {
  if (typeof str != "string") return "";
  let front = str.substring(0, 6);
  let behind = str.substring(str.length - 6, str.length);
  return `${front}***${behind}`;
};

/**
 * 日期格式转换
 * @param {*} date
 * @returns
 */
export const transformDate = (date) => {
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  var h = date.getHours();
  h = h < 10 ? "0" + h : h;
  var minute = date.getMinutes();
  minute = minute < 10 ? "0" + minute : minute;
  var second = date.getSeconds();
  second = second < 10 ? "0" + second : second;
  return y + "-" + m + "-" + d;
  // return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
};
/**
 * 获取随技术
 * @param {Number} min 最小
 * @param {Number} max 最大
 */
export const getRandom = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * 判断系统源 是ios 还是安卓
 */
export const appSource = () => {
  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if (isiOS) {
    return "ios";
  } else {
    return "andriod";
  }
};
