import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

//mock数据模拟
import "@/request/mock";

Vue.config.productionTip = false

import {
  Button,
  Icon,
  Popup,
  Grid,
  GridItem,
  Image as VanImage,
  Loading,
  Notify,
  Field,
  CountDown,
  Cell,
  CellGroup,
  Stepper,
  Toast,
  DatetimePicker,
  Picker,
  Divider,
  NavBar,
  Swipe,
  SwipeItem,
  Overlay,
  Lazyload,
  Tab,
  Tabs,
  Popover,
  Area,
  RadioGroup,
  Radio,
  Search,
  DropdownMenu, 
  DropdownItem,
  NoticeBar
} from "vant";
Vue.use(Button);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(VanImage);
Vue.use(Loading);
Vue.use(Notify);
Vue.use(Field);
Vue.use(CountDown);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Stepper);
Vue.use(Toast);
Vue.use(DatetimePicker);
Vue.use(Picker);
Vue.use(Divider);
Vue.use(NavBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Overlay);
Vue.use(Lazyload);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Popover);
Vue.use(Area);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Search);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(NoticeBar);



import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)


// import Vconsole from 'vconsole';
// const vConsole = new Vconsole();
// Vue.use(vConsole)
import i18n from '@/language'

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')