
/**
 * 获取缓存
 * @param {*} key 
 * @returns 
 */
export const getStorage = (key) => {
    return sessionStorage.getItem(key)
};

/**
 * 设置缓存
 * @param {*} key 
 * @param {*} data 
 */
export const setStorage = (key, data) => {
    sessionStorage.setItem(key, data)
};

/**
 * 删除缓存
 * @param {*} key 
 */
export const removeStorage = (key) => {
    sessionStorage.removeItem(key)
};

/**
 * 清空缓存
 */
export const clearStorage = () => {
    sessionStorage.clear()
};


