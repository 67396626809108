/**
 * 案例:https://docs.metamask.io/guide/sending-transactions.html#example
 * http://cw.hubwiz.com/card/c/metamask-api/1/2/9/ 中文api
 * web3 : https://blog.csdn.net/qq_16137795/article/details/120239103?utm_medium=distribute.pc_aggpage_search_result.none-task-blog-2~aggregatepage~first_rank_ecpm_v1~rank_v31_ecpm-1-120239103.pc_agg_new_rank&utm_term=web3+%E8%B0%83%E7%94%A8MetaMask%E9%92%B1%E5%8C%85%E8%BD%AC%E8%B4%A6eth&spm=1000.2123.3001.4430
 */
import Web3 from "web3";
// import contractAbi from "./contractAbi"
import { appSource } from "@/common/utils";
//https://blog.csdn.net/feiying0canglang/article/details/125117080 精度计算
import BigNumber from "bignumber.js";

export const usdcTokenAddress = "0x55d398326f99059ff775485246999027b3197955"; //usdc
export const mccTokenAddress = "0x8fffeb91f0d32c2c9806e1b2B909A8775ED27F4A"; //MCC
// export const collectionAddress = "0x082F519EE1D110739B5C8E976CcD0aF367c19a3f" //支付接收地址 (质押接收 购买接收)
export const zhiyacollectionAddress =
  "0x58441E6F91C8767195707aD898cdE4c8DCf30597"; //质押接收地址
export const simucollectionAddress =
  "0xb19FF5B562C36c294Df83A141C38e83bdC8B4211"; //私募接收地址

// 0xbA712aD411Eda83d345d9beD34245bc3E7E31856 转账地址换成这个
//   //链更改 刷新页面
//   window.ethereum.on("chainChanged", (_chainId) => window.location.reload());

/**
 * 链接账户 MetaMask
 */
export const requestAccounts = async () => {
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  console.log(accounts);
  return await accounts[0];
};

/**
 * 获取 chainId
 */
export const getChainId = async () => {
  const chainId = await window.ethereum.request({
    method: "eth_chainId",
  });
  return chainId;
  // return new Promise((resolve, reject) => {
  //     let chainIdTime = null
  //     setTimeout(()=>{console.log(window.ethereum)},2000)
  //     chainIdTime = setInterval(() => {
  //         const chainId = window.ethereum
  //         if (chainId?.chainId) {
  //             clearInterval(chainIdTime)
  //             chainIdTime = null
  //             resolve(chainId.chainId)
  //         }
  //     }, 100)
  // })
};

/**
 * 获取 账户
 */
export const getAccounts = async () => {
  return new Promise((resolve, reject) => {
    window.ethereum
      .request({ method: "eth_accounts" })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getRequestAccounts = async () => {
  return new Promise((resolve, reject) => {
    window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// /**
//  * 获取代币交易记录
//  */
// export const getTransactionToken = (from, to) => {
//   web3 = new Web3();
//   console.log(web3.eth);
//   return;
//   return new Promise((resolve, reject) => {
//     window.ethereum
//       .request({
//         jsonrpc: "2.0",
//         method: "account_signTransaction",
//         params: [
//           {
//             from,
//             to,
//             data: "0x70a08231000000000000000000000000" + from.split("0x")[1],
//           },
//           "latest",
//         ],
//         id: 2,
//       })
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };

/**
 * 获取 动态的 GasLimit 值
 * @param {*} from 当前地址
 * @param {*} to  合约地址
 * @returns
 */
export const getGasLimit = (from, to, collection, amount) => {
  return new Promise(async (resolve, reject) => {
    const web3 = new Web3(window.web3.currentProvider);
    const _amount = new BigNumber(amount).times(Math.pow(10, 18));
    const gasPrice = await web3.eth.getGasPrice(); // estimate the gas price
    const transactionObject = {
      from, //当前地址
      to, ///合约地址
      gasPrice,
      // value: _amount.toNumber(),
      value: "0x00",
      // data的组成，由：0x + 要调用的合约方法的function signature + 要传递的方法参数，每个参数都为64位(对transfer来说，第一个是接收人的地址去掉0x，第二个是代币数量的16进制表示，去掉前面0x，然后补齐为64位)
      // data: '0x' + 'a9059cbb' + addPreZero(collection.substr(2)) + addPreZero(web3.utils.toHex(math.multiply(amount, Math.pow(10, 18))).substr(2))
      data:
        "0x" +
        "a9059cbb" +
        addPreZero(collection.substr(2)) +
        addPreZero(_amount.toString(16)),
    };
    // const gasLimit = await this.web3.eth.estimateGas(transactionObject);
    // resolve(gasLimit)
    console.log(transactionObject);
    web3.eth
      .estimateGas(transactionObject)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};



/**
 * 获取余额
 * @param {*} address
 */
export const getBalance = async (address) => {
  const balance =
    (await window.ethereum.request({
      method: "eth_getBalance",
      params: [address, "latest"],
      id: 1,
    })) / Math.pow(10, 18);
  return await balance;
};

/**
 * 获取交易状态
 * @param {*} id
 * https://eth.wiki/json-rpc/API#json-rpc-methods
 */
export const getTransactionByHash = async (id) => {
  const status = await window.ethereum.request({
    method: "eth_getTransactionByHash",
    params: [id],
  });
  return await status;
};
/**
 * 获取交易状态(轮询)
 * @param {*} id
 * @returns txid
 */
export const asyncGetTransactionByHash = async (id) => {
  return new Promise((resolve, reject) => {
    let setI = null;
    setI = setInterval(() => {
      window.ethereum
        .request({
          method: "eth_getTransactionByHash",
          params: [id],
        })
        .then((res) => {
          // console.log(res)
          if (res.transactionIndex) {
            clearInterval(setI);
            setI = null;
            resolve(id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500);
  });
};

/**
 * 获取代币余额
 * 要想获取代币的余额，要通过rpc接口得到接口为：eth_call
 * @param {*} from  钱包地址
 * @param {*} to  合约地址 (代币地址)（智能合约地址）
 * data数据格式：最前边的“0x70a08231000000000000000000000000”是固定的，后边的是钱包地址（不带“0x”前缀）
 *
 */
export const getTokenBalance = async (from, to, type) => {
  return new Promise((resolve, reject) => {
    const params = [
      {
        from,
        to,
        data: "0x70a08231000000000000000000000000" + from.split("0x")[1],
      },
      "latest",
    ]
    window.ethereum
      .request({
        jsonrpc: "2.0",
        method: "eth_call",
        params,
        id: 1,
      })
      .then((txHash) => {
        if (type == "usdc" || type == "usdt") {
          const _num = parseInt(txHash);
          const _num_ = _num / Math.pow(10, 6);
          resolve(_num_);
          return;
        }

        if (txHash == "0x") {
          resolve(0);
          return;
        }
        if (type == "mcc") {
          resolve(parseInt(txHash) / Math.pow(10, 4));
          return;
        }
        const balance = txHash / Math.pow(10, 18);
        resolve(balance.toFixed(4));
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

/**
 * 
 * 说明
 
    1、ethereum.networkVersion属性返回MetaMask当前接入的区块链的网络ID
    1：以太坊主网
    2：Morden测试链
    3：Ropsten测试链
    4：Rinkeby测试链
    42：Kovan测试链
    获取链id     console.log(ethereum.networkVersion);
 
    2、获取当前选中账号 console.log(ethereum.selectedAddress)
 
 
    3、ethereum.isMetaMask返回true或false，表示当前用户是否安装了MetaMask。
    console.log('MetaMask installed ? ', ethereum.isMetaMask)
 
 
    4、当用户切换网络时，MetaMask扩展会自动刷新。 ethereum.autoRefreshOnNetworkChange这个实验性质的属性允许你关闭 默认的网络切换自动刷新功能。
    如果你不希望切换网络时自动重载，可以使用如下代码关闭这个属性：
    ethereum.autoRefreshOnNetworkChange = false;
 
 */

// 补齐64位，不够前面用0补齐
function addPreZero(num) {
  var t = (num + "").length,
    s = "";
  for (var i = 0; i < 64 - t; i++) {
    s += "0";
  }
  return s + num;
}

/**
 * busd 转账交易
 * @param {*} from 发件人地址
 * @param {*} to 合约地址
 * @param {*} amount 金额
 * @returns
 * transfer
 * https://docs.metamask.io/guide/sending-transactions.html#transaction-parameters
 * http://cw.hubwiz.com/card/c/metamask-api/1/2/8/
 * https://blog.csdn.net/ffzhihua/article/details/81126932?utm_medium=distribute.pc_aggpage_search_result.none-task-blog-2~aggregatepage~first_rank_ecpm_v1~rank_v31_ecpm-4-81126932.pc_agg_new_rank&utm_term=abi+eth+%E4%BB%A3%E5%B8%81usdt%E8%BD%AC%E8%B4%A6&spm=1000.2123.3001.4430
 * https://blog.csdn.net/Little_Ji/article/details/103138502?spm=1001.2101.3001.6650.5&utm_medium=distribute.pc_relevant.none-task-blog-2%7Edefault%7EBlogCommendFromBaidu%7ERate-5.pc_relevant_paycolumn_v3&depth_1-utm_source=distribute.pc_relevant.none-task-blog-2%7Edefault%7EBlogCommendFromBaidu%7ERate-5.pc_relevant_paycolumn_v3&utm_relevant_index=10
 */

export const busdTransferAccounts = async (from, to, collection, amount, gasLimit) => {
  // console.log(amount);
  // web3 = new Web3();
  return new Promise((resolve, reject) => {
    const web3 = new Web3(window.web3.currentProvider);
    const getGasPrice = web3.eth.getGasPrice(); // estimate the gas price
    const BigNumber_amount = new BigNumber(amount);
    const _amount = BigNumber_amount.times(Math.pow(10, 18));
    const params = [
      {
        // nonce: '0x00', // 被MetaMask忽略
        // gasPrice: '0x09184e72a000', // 用于交易的gas价格，单位：wei
        // 设置gasLimit和gasPrice
        // gasLimit: web3.utils.toHex(99000),
        // gasPrice: web3.utils.toHex(10e9),
        // gas: '0x9dd0', //交易可用的最大gas量，即gas limit
        gas: web3.utils.toHex(gasLimit),
        getGasPrice: web3.utils.toHex(getGasPrice),
        gasLimit: web3.utils.toHex(gasLimit),
        from, //发件人地址
        to, //交易发送方地址
        // value: (amount * Math.pow(10, 18)).toString(16), //只需要从发起的外部帐户向收件人发送以太。
        // data: '0x7f7465737432000000000000000000000000000000000000000000000000000000600057',//可选，附属的数据，可做智能合约函数入参
        // chainId: '0x3', // 用于防止跨区块链的交易重用。由MetaMask自动填充。
        value: "0x00",
        // data的组成，由：0x + 要调用的合约方法的function signature + 要传递的方法参数，每个参数都为64位(对transfer来说，第一个是接收人的地址去掉0x，第二个是代币数量的16进制表示，去掉前面0x，然后补齐为64位)
        // data: '0x' + 'a9059cbb' + addPreZero(collection.substr(2)) + addPreZero(web3.utils.toHex(math.multiply(amount, Math.pow(10, 18))).substr(2))
        data:
          "0x" +
          "a9059cbb" +
          addPreZero(collection.substr(2)) +
          addPreZero(_amount.toString(16)),
      },
    ];
    console.log(params);
    // if (appSource() == "andriod") {
    //   params[0].gas = "0xd6d8";
    // }
    window.ethereum
      .request({
        method: "eth_sendTransaction",
        params,
      })
      .then((txHash) => {
        // console.log(txHash)
        resolve(txHash);
      })
      .catch((error) => {
        reject(error);
        // if (error.code === 4001) {
        //     // EIP-1193 userRejectedRequest error
        //     console.log('Please connect to MetaMask.');
        // } else {
        //     console.error(error);
        // }
      });
  });
};

export const usdcTransferAccounts = async (from, to, collection, amount) => {
  // console.log(amount);
  web3 = new Web3();
  return new Promise(function (resolve, reject) {
    const BigNumber_amount = new BigNumber(amount);
    const _amount = BigNumber_amount.times(Math.pow(10, 6));
    const params = [
      {
        // nonce: '0x00', // 被MetaMask忽略
        // gasPrice: '0x09184e72a000', // 用于交易的gas价格，单位：wei
        // 设置gasLimit和gasPrice
        // gasLimit: web3.utils.toHex(99000),
        // gasPrice: web3.utils.toHex(10e9),
        // gas: '0x9dd0', //交易可用的最大gas量，即gas limit
        from, //发件人地址
        to, //交易发送方地址
        // value: (amount * Math.pow(10, 18)).toString(16), //只需要从发起的外部帐户向收件人发送以太。
        // data: '0x7f7465737432000000000000000000000000000000000000000000000000000000600057',//可选，附属的数据，可做智能合约函数入参
        // chainId: '0x3', // 用于防止跨区块链的交易重用。由MetaMask自动填充。
        value: "0x00",
        // data的组成，由：0x + 要调用的合约方法的function signature + 要传递的方法参数，每个参数都为64位(对transfer来说，第一个是接收人的地址去掉0x，第二个是代币数量的16进制表示，去掉前面0x，然后补齐为64位)
        // data: '0x' + 'a9059cbb' + addPreZero(collection.substr(2)) + addPreZero(web3.utils.toHex(math.multiply(amount, Math.pow(10, 18))).substr(2))
        data:
          "0x" +
          "a9059cbb" +
          addPreZero(collection.substr(2)) +
          addPreZero(_amount.toString(16)),
      },
    ];
    if (appSource() == "andriod") {
      params[0].gas = "0xd6d8";
    }
    window.ethereum
      .request({
        method: "eth_sendTransaction",
        params,
      })
      .then((txHash) => {
        // console.log(txHash)
        resolve(txHash);
      })
      .catch((error) => {
        reject(error);
        // if (error.code === 4001) {
        //     // EIP-1193 userRejectedRequest error
        //     console.log('Please connect to MetaMask.');
        // } else {
        //     console.error(error);
        // }
      });
  });
};

export const mccTransferAccounts = async (from, to, collection, amount) => {
  // console.log(amount);
  web3 = new Web3();
  return new Promise(function (resolve, reject) {
    const BigNumber_amount = new BigNumber(amount);
    const _amount = BigNumber_amount.times(Math.pow(10, 4));
    const params = [
      {
        // nonce: '0x00', // 被MetaMask忽略
        gasPrice: "0x09184e72a000", // 用于交易的gas价格，单位：wei
        // 设置gasLimit和gasPrice
        gasLimit: web3.utils.toHex(99000),
        gasPrice: web3.utils.toHex(10e9),
        gas: "0x9dd0", //交易可用的最大gas量，即gas limit

        from, //发件人地址
        to, //交易发送方地址
        // value: (amount * Math.pow(10, 18)).toString(16), //只需要从发起的外部帐户向收件人发送以太。
        // data: '0x7f7465737432000000000000000000000000000000000000000000000000000000600057',//可选，附属的数据，可做智能合约函数入参
        // chainId: '0x3', // 用于防止跨区块链的交易重用。由MetaMask自动填充。
        value: "0x00",
        // data的组成，由：0x + 要调用的合约方法的function signature + 要传递的方法参数，每个参数都为64位(对transfer来说，第一个是接收人的地址去掉0x，第二个是代币数量的16进制表示，去掉前面0x，然后补齐为64位)
        // data: '0x' + 'a9059cbb' + addPreZero(collection.substr(2)) + addPreZero(web3.utils.toHex(math.multiply(amount, Math.pow(10, 18))).substr(2))
        data:
          "0x" +
          "a9059cbb" +
          addPreZero(collection.substr(2)) +
          addPreZero(_amount.toString(16)),
      },
    ];
    if (appSource() == "andriod") {
      params[0].gas = "0xd6d8";
    }
    window.ethereum
      .request({
        method: "eth_sendTransaction",
        params,
      })
      .then((txHash) => {
        // console.log(txHash)
        resolve(txHash);
      })
      .catch((error) => {
        reject(error);
        // if (error.code === 4001) {
        //     // EIP-1193 userRejectedRequest error
        //     console.log('Please connect to MetaMask.');
        // } else {
        //     console.error(error);
        // }
      });
  });
};
export const getGasLimit2 = (from, to, amount) => {
  return new Promise(async (resolve, reject) => {
    const web3 = new Web3(window.web3.currentProvider);
    const _amount = new BigNumber(amount).times(Math.pow(10, 18));
    const gasPrice = await web3.eth.getGasPrice(); // estimate the gas price
    const transactionObject = {
      from, //当前地址
      to, ///合约地址
      gasPrice,
      // value: _amount.toNumber(),
      value: '0x' + _amount.toString(16),
      // data的组成，由：0x + 要调用的合约方法的function signature + 要传递的方法参数，每个参数都为64位(对transfer来说，第一个是接收人的地址去掉0x，第二个是代币数量的16进制表示，去掉前面0x，然后补齐为64位)
      // data: '0x' + 'a9059cbb' + addPreZero(collection.substr(2)) + addPreZero(web3.utils.toHex(math.multiply(amount, Math.pow(10, 18))).substr(2))
      // data:
      //   "0x" +
      //   "a9059cbb" +
      //   addPreZero(collection.substr(2)) +
      //   addPreZero(_amount.toString(16)),
    };
    console.log(transactionObject);

    web3.eth
      .estimateGas(transactionObject)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/**
 * 以太坊平台转账
 * @param {*} from 发送地址
 * @param {*} to 接收地址
 * @param {*} amount 金额
 */
export const sendTransaction = (from, to, amount, gasLimit) => {
  return new Promise((resolve, reject) => {
    const web3 = new Web3(window.web3.currentProvider);
    const getGasPrice = web3.eth.getGasPrice();
    const BigNumber_amount = new BigNumber(amount);
    const _amount = BigNumber_amount.times(Math.pow(10, 18));

    const params = [
      {
        from,
        to,
        // value: addPreZero(_amount.toString(16)),
        value: '0x' + _amount.toString(16),
        // gasPrice: '0x09184e72a000',
        // gas: '0x2710',
        gas: web3.utils.toHex(gasLimit),
        // gasPrice: getGasPrice,
        // gasLimit: Number(new BigNumber(gasLimit).times(2))
        getGasPrice: web3.utils.toHex(getGasPrice),
        gasLimit: web3.utils.toHex(gasLimit),
      },
    ];
    window.ethereum
      .request({
        method: "eth_sendTransaction",
        params,
      })
      .then((txHash) => {
        resolve(txHash);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * 绑定上下级
 * @param {*} param0
 * superior 上级地址/地址栏获取
 * from 当前钱包地址
 * collection 合约地址
 * uId 用户id
 * @returns
 */
export const setsuperior = async ({ superior, from, collection, uId }) => {
  return new Promise(function (resolve, reject) {
    const params = [
      {
        from, //发件人地址
        to: collection, //合约地址
        value: "0x00",
        //to=接收地址
        data:
          "0x" +
          "883aef22" +
          addPreZero(from.substr(2)) +
          addPreZero(superior.substr(2)) +
          addPreZero(uId.toString(16)),
      },
    ];
    window.ethereum
      .request({
        method: "eth_sendTransaction",
        params,
      })
      .then((txHash) => {
        resolve(txHash);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
