import { render, staticRenderFns } from "./i18n.vue?vue&type=template&id=11e67ee8&scoped=true"
import script from "./i18n.vue?vue&type=script&lang=js"
export * from "./i18n.vue?vue&type=script&lang=js"
import style0 from "./i18n.vue?vue&type=style&index=0&id=11e67ee8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11e67ee8",
  null
  
)

export default component.exports