<template>
  <div id="app">
    <navBar
      :title="title"
      @openMenu="menu_operation"
      :bgColor="navBarBg"
      :logo="path == '/home'"
    >
      <template v-if="path != '/home' && path != '/manage'" #logo>
        <van-icon name="cross" color="#333333" size="26" @click="routerGo" />
      </template>

      <template v-if="is_more(meta)" #right>
        <van-popover
          v-if="is_moreMenu(meta)"
          v-model="showPopover"
          placement="bottom-end"
          trigger="click"
          :actions="meta.moreMenu"
          @select="onSelect"
        >
          <template #reference>
            <div class="navbar-more">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </template>
        </van-popover>
        <div class="link" @click="routerPush(meta.moreMenu.path)" v-else>
          {{ $t(meta.moreMenu.text) }}
        </div>
      </template>
    </navBar>
    <div class="router-canvas">
      <router-view />
      <van-overlay :show="loading" z-index="10" />
      <van-loading class="loading" color="#fff" v-if="loading" size="24px"
        >Connecting...</van-loading
      >
      <menu-view :show="menu_show" @closeMenu="menu_operation" />
    </div>
  </div>
</template>
<script>
import { requestAccounts } from "@/common/metaMask/index";
import { mapState } from "vuex";
import { login } from "@/request/api";
import navBar from "@/components/navBar";
import menuView from "@/components/menu";
export default {
  components: { navBar, menuView },
  computed: {
    ...mapState({
      walletAddress: (state) => state.user.walletAddress,
      token: (state) => state.user.token,
      // menuShow: state => state.system.menuShow,
    }),
  },
  mounted() {
    window.ethereum.on("chainChanged", (_chainId) => {
      // console.log(_chainId)
      window.location.reload();
    });
    //账户更改
    window.ethereum.on("accountsChanged", (accounts) => {
      this.loading = true;
      this.loginFun(accounts[0]);
    });
  },
  watch: {
    $route(v) {
      this.path = v.path;
      this.meta = v.meta;
      this.title = v.meta.title;
      this.navBarBg = v.meta.navBarBg;
      this.menu_operation(false);
      if (!this.token || !this.walletAddress) {
        this.loading = true;
        // console.log("链接前")
        requestAccounts().then((res) => {
          // console.log("链接后:", res);
          const ref = v.query?.ref ? v.query.ref : "";
          this.loginFun(res, ref);
        });
      }
    },
  },
  data() {
    return {
      loading: false,
      address: this.$route.query.address || "",
      title: "",
      menu_show: false,
      path: "",
      meta: {},
      navBarBg: "#F4FFF7",
      showPopover: false,
    };
  },
  methods: {
    onSelect(action) {
      this.routerPush(action.path);
    },
    /**
     * 是否存在更多
     * @param {Object} item
     */
    is_more(item) {
      return item?.moreMenu;
    },
    /**
     * 判断是对象还是 数组
     * @param {*} item
     */
    is_moreMenu(item) {
      if (!item?.moreMenu) return false;
      if (Array.isArray(item.moreMenu)) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 返回上一页
     */
    routerGo() {
      this.$router.go(-1);
    },
    /**
     * 跳转
     * @param {*} path
     */
    routerPush(path) {
      this.$router.push({ path });
    },
    /**
     * 菜单操作
     * @param {*} show
     */
    menu_operation(show) {
      this.menu_show = show;
    },
    /**
     * 账户id
     * @param {*} id
     */
    loginFun(id, ref = "") {
      // console.log("登陆前")
      // console.log(router.history.query)
      this.$store.dispatch("user/set_wallet_address", id);
      login({
        address: id,
        ref,
      }).then((res) => {
        // console.log("登陆后:", res)
        this.loading = false;
        if (res.code == 200) {
          this.$store.dispatch("user/set_token", res.data.token);
          this.$store.dispatch("user/set_code", res.data.code);
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss">
// 引入全局样式
@import "@/assets/style/common.scss";

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;

  .van-loading__text {
    margin-top: 20px;
  }
}

.navbar-more {
  display: flex;
  align-items: center;

  span {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #333333;
    margin-left: 6px;
  }
}
</style>
