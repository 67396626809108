import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import routes from "./routes"
import store from '@/store'

import NProgress from "nprogress";
import "nprogress/nprogress.css";


const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL, //默认值: "/" , 应用的基路径。例如，如果整个单页应用服务在 /app/ 下，然后 base 就应该设为 "/app/" https://router.vuejs.org/zh/api/#base
  routes
})

//路由守卫
router.beforeEach((to, from, next) => {
  // 进度条
  NProgress.start(); // 显示进度条
  NProgress.set(0.4); // 设置百分比
  NProgress.inc(); // 稍微增加
  // document.title = to.meta.title

  // 解决长滚动 出现的返回顶部 bug start
  let classArr = document.getElementsByClassName("mescroll-totop")
  if (classArr.length != 0) {
    classArr[0].remove()
  }
  // 解决长滚动 出现的返回顶部 bug end

  if (to.matched.some((record) => record.meta.requireLogin)) {
    if (!to.meta.requireLogin) {
      next();
    } else {
      let token = store.state.user.token;
      if (!token) {
        next({
          path: '/'
        });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});
router.afterEach(() => {
  NProgress.done(); //完成进度(进度条消失)
});

export default router