<template>
  <van-popup
    class="menu"
    v-model="menu_show"
    position="left"
    @close="close_menu"
  >
    <div class="logo">
      <img class="logo-bg" src="@/assets/images/menu/logo-bg.png" alt="" />
      <img class="logo-image" src="@/assets/images/menu/logo2.png" alt="" />
    </div>
    <div class="menu-view">
      <div class="menu-item" @click="routerPush('/home')">
        <img src="@/assets/images/menu/menu-1.png" alt="" />
        <div class="name">{{ $t("menu.home") }}</div>
      </div>
      <div class="menu-item" @click="routerPush('/pledge')">
        <img src="@/assets/images/menu/menu-2.png" alt="" />
        <div class="name">{{ $t("menu.pledge") }}</div>
      </div>
      <div class="menu-item" @click="routerPush('/center')">
        <img src="@/assets/images/menu/menu-3.png" alt="" />
        <div class="name">{{ $t("menu.center") }}</div>
      </div>
      <div class="menu-item" @click="routerPush('/kline')">
        <img src="@/assets/images/menu/menu-7.png" alt="" />
        <div class="name">
          {{ $t("menu.transaction") }}
        </div>
      </div>
      <div class="menu-item">
        <img src="@/assets/images/menu/menu-11.png" alt="" />
        <div class="name">
          {{ $t("menu.ai") }}
          <div class="tag">{{ $t("menu.notOpen") }}</div>
        </div>
      </div>
      <div class="menu-item">
        <img src="@/assets/images/menu/menu-4.png" alt="" />
        <div class="name">
          {{ $t("menu.chainGame") }}
          <div class="tag">{{ $t("menu.notOpen") }}</div>
        </div>
      </div>
      <div class="menu-item">
        <img src="@/assets/images/menu/menu-5.png" alt="" />
        <div class="name">
          {{ $t("menu.manageMoney") }}
          <div class="tag">{{ $t("menu.notOpen") }}</div>
        </div>
      </div>
      <div class="menu-item">
        <img src="@/assets/images/menu/menu-6.png" alt="" />
        <div class="name">
          {{ $t("menu.metaverse") }}
          <div class="tag">{{ $t("menu.notOpen") }}</div>
        </div>
      </div>

      <div class="menu-item">
        <img src="@/assets/images/menu/menu-8.png" alt="" />
        <div class="name">
          {{ $t("menu.market") }}
          <div class="tag">{{ $t("menu.notOpen") }}</div>
        </div>
      </div>
      <div class="menu-item">
        <img src="@/assets/images/menu/menu-10.png" alt="" />
        <div class="name">
          {{ $t("menu.vote") }}
          <div class="tag">{{ $t("menu.notOpen") }}</div>
        </div>
      </div>
      <div class="menu-item">
        <img src="@/assets/images/menu/menu-9.png" alt="" />
        <div class="name">
          {{ $t("menu.contactUs") }}
          <div class="tag">{{ $t("menu.notOpen") }}</div>
        </div>
      </div>
      <div class="menu-item" @click="openI18nPopup">
        <img src="@/assets/images/menu/menu-12.png" alt="" />
        <div class="name">
          {{ $t("menu.languageSwitching") }}
          <div
            class="tag"
            style="background-color: #daab42"
            v-if="lang == 'zh'"
          >
            {{ $t("lang.zh") }}
          </div>
          <div
            class="tag"
            style="background-color: #daab42"
            v-if="lang == 'en'"
          >
            {{ $t("lang.en") }}
          </div>
        </div>
      </div>
    </div>
    <i18n-popup ref="i18n" />
  </van-popup>
</template>

<script>
import i18nPopup from "@/components/popup/i18n";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      lang: (state) => state.system.lang,
    }),
  },
  components: {
    i18nPopup,
  },
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      menu_show: false,
    };
  },
  watch: {
    show: {
      handler(newValue, oldValue) {
        this.menu_show = newValue;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    close_menu() {
      this.$emit("closeMenu", false);
      // this.$store.dispatch("system/set_menu", false);
    },
    routerPush(path) {
      // this.$store.dispatch("system/set_menu", false);
      if (this.$route.path == path) return;
      this.$router.push({ path });
    },
    openI18nPopup() {
      this.$refs.i18n.open();
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  &.van-popup--left {
    width: 60vw;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 10px 0;

    .logo {
      position: relative;

      .logo-bg {
        width: 100%;
        display: block;
      }

      .logo-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        display: block;
      }
    }

    .menu-view {
      flex: 1;
      // overflow: hidden;
      overflow-y: auto;
      padding: 30px 0 0 12px;

      .menu-item {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        line-height: 1;

        img {
          width: 28px;
          display: block;
          margin-right: 6px;
        }

        .name {
          font-size: 15px;
          color: #333333;
          display: flex;
          align-items: center;

          .tag {
            padding: 0 8px;
            height: 22px;
            background: #f0844a;
            border-radius: 8px;
            font-size: 12px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>
