<template>
  <van-nav-bar safe-area-inset-top class="nav-bar" :title="$t(title)" :style="{backgroundColor:bgColor}" :border="false"
    :fixed="fixed">
    <template #left>
      <div class="navBar-left">
        <div v-if="logo" class="open" @click="open_menu">
          <img src="@/assets/images/menu/open1.png" alt=""/>
        </div>
        <slot name="logo" />
      </div>
    </template>
    <template #right>
      <slot name="right" />
    </template>
  </van-nav-bar>
</template>

<script>
export default {
  props: {
    //black=黑色  white=白色  也可以十六进制色值
    color: {
      type: String,
      default() {
        return "black";
      },
    },
    title: {
      type: String,
      default() {
        return "";
      },
    },
    bgColor: {
      type: String,
      default() {
        return "rgba(255, 215, 156, 0.2)";
      },
    },
    logoType: {
      type: String,
      default() {
        return "1";
      },
    },
    fixed: {
      type: Boolean,
      default() {
        return false;
      },
    },
    logo: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {};
  },
  mounted() { },
  methods: {
    /**
     * 返回上一页
     */
    routerGo() {
      this.$router.go(-1);
    },
    routerPush(path) {
      this.$router.push({ path });
    },
    /**
     * 打开菜单
     */
    open_menu() {
      this.$emit("openMenu", true)
      // this.$store.dispatch("system/set_menu", true);
    }
  },
};
</script>
<style lang="scss" scoped>
.open {
  width: 48px;
  height: 48px;
  border-radius:50%;
  background-color: #B48418;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 60%;
    display: block;
  }
}
/deep/ .van-nav-bar__content {
  .van-nav-bar__left {
    padding: 0 14px;
  }

  .van-nav-bar__right {
    padding: 0 14px;
  }

  .navBar-left {
    display: flex;
    align-items: center;
    line-height: 1;

    // img {
    //   width: 24px;
    //   display: block;
    // }

    .circle {
      width: 23px;
      height: 23px;
      border-width: 3px;
      border-style: solid;
      border-radius: 50%;
      box-sizing: border-box;
      margin-right: 10px;
    }

    .logo {
      font-size: 23px;
      font-weight: bold;
      line-height: 1;
    }
  }

  .more {
    display: flex;
    align-items: center;

    span {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: #333333;
      margin-left: 3px;
    }
  }
}

.logo-text {
  margin-right: 10px;
}
</style>